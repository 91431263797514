<template>
  <div class="appointment-popup-overlay" v-if="hasAppointment" @click="closeClick">
    <div class="appointment-popup" @click="closePopupClick">
      <div class="clearfix">
        <span class="case-ref">{{ appointment.CaseReference }}</span>
      </div>
      <hr/>

      <div class="float-container clearfix">
        <table class="borderless-table">
          <tbody>
          <tr v-for="item in getAppointmentDetails" :key='item'>
            <td>{{ item.column }}</td>
            <td>{{ item.value }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      
      <hr/>
      
      <div class="float-container clearfix">
        <table>
          <thead>
            <tr @click="onToggleInvoiceInfoClick">
              <th colspan="2" class="panel-heading" :class="toggleInvoiceInfo ? 'active' : ''"><a>Invoice Information</a></th>
            </tr>
          </thead>
          <tbody v-if="toggleInvoiceInfo" class="invoice-info">
            <tr v-for="item in getInvoiceInformation" :key='item'>
              <td>{{ item.column }}</td>
              <td>{{ item.value }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="2">Click header to see data</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2">&nbsp;</td>
            </tr>
          </tfoot>
        </table>
      </div>
      
      <div class="float-container clearfix">
        <table>
          <thead>
            <tr v-if="hasRemittanceData" @click="onToggleRemittancesClick">
              <th colspan="2" class="panel-heading" :class="toggleRemittances ? 'active' : ''"><a>{{ remittanceHeader }}</a></th>
            </tr>
            <tr v-else>
              <th colspan="2">{{ remittanceHeader }}</th>
            </tr>
          </thead>
          <tbody v-if="hasRemittanceData && toggleRemittances">
            <tr v-for="item in getRemittanceData" :key='item'>
              <td v-if="item.column !== '-'">{{ item.column }}</td>
              <td v-if="item.column !== '-'">{{ item.value }}</td>
              <td v-if="item.column === '-'" colspan="2"><hr/></td>
            </tr>
          </tbody>
          <tbody v-else-if="hasRemittanceData && !toggleRemittances">
            <tr>
              <td colspan="2">Click header to see data</td>
            </tr>
          </tbody>
          <tbody v-else-if="!hasRemittanceData">
            <tr>
              <td colspan="2">No Remittances</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2">&nbsp;</td>
            </tr>
          </tfoot>
        </table>
      </div>
      
      <div class="float-container clearfix">
        <table>
          <thead>
            <tr v-if="hasAuditData" @click="onToggleAuditHistoryClick">
              <th colspan="2" class="panel-heading" :class="toggleAuditHistory ? 'active' : ''"><a>{{ auditHeader }}</a></th>
            </tr>
            <tr v-else>
              <th colspan="2">{{ auditHeader }}</th>
            </tr>
          </thead>
          <tbody v-if="hasAuditData && toggleAuditHistory">
            <tr v-for="item in getAuditData" :key='item'>
              <td v-if="item.column !== '-'">{{ item.column }}</td>
              <td v-if="item.column !== '-'">{{ item.value }}</td>
              <td v-if="item.column === '-'" colspan="2"><hr/></td>
            </tr>
          </tbody>
          <tbody v-else-if="hasAuditData && !toggleAuditHistory">
          <tr>
            <td colspan="2">Click header to see data</td>
          </tr>
          </tbody>
          <tbody v-else-if="!hasAuditData">
            <tr>  
              <td colspan="2">No Audit History</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2">&nbsp;</td>
            </tr>
          </tfoot>
        </table>
      </div>
      
    </div>  
  </div>
  
</template>

<script>
import {lookups} from "@/lookups"
import helpers from "@/common"

export default {
  name: "AppointmentDetails",
  props: {
    appointment: { type: Object }
  },
  emits: ["appointment-details-closed"],
  data() {
    return {
      toggleInvoiceInfo: true,
      toggleRemittances: false,
      toggleAuditHistory: false
    }
  },
  computed: {
    hasAppointment() {
      return this.appointment !== null
    },
    getAppointmentDetails () {
      const invoiceInfoDetails = [];

      invoiceInfoDetails.push({column: "Case Ref:", value: this.appointment.CaseReference})
      invoiceInfoDetails.push({column: "Appointment Type:", value: this.appointment.AppointmentType})
      invoiceInfoDetails.push({column: "Appointment Date", value: helpers.getAppointmentDate(this.appointment.AppointmentDate)})
      invoiceInfoDetails.push({column: "Appointment Status:", value: this.appointment.Attendance})
      invoiceInfoDetails.push({column: "Status:", value: lookups.getStatusDisplayText(this.appointment.ClientInvoiceStatus)})

      return invoiceInfoDetails
    },
    getInvoiceInformation() {
      const invoiceInfoDetails = [];

      invoiceInfoDetails.push({column: "Invoice ID:", value: this.appointment.ClientInvoiceNumber})
      invoiceInfoDetails.push({column: "Date Raised:", value: helpers.getDisplayDateOnly(this.appointment.CreatedOn)})
      invoiceInfoDetails.push({column: "Value", value: "£" + this.appointment.Cost})
      invoiceInfoDetails.push({column: "Value Paid By Client:", value: "£" + this.appointment.PaidByClient})
      invoiceInfoDetails.push({column: "Value Paid By Patient:", value: "£" + this.appointment.PaidByPatient})
      invoiceInfoDetails.push({column: "Value Written Off:", value: "£" + this.appointment.ValueWrittenOff})
      invoiceInfoDetails.push({column: "Balance:", value: "£" + this.appointment.Balance})

      return invoiceInfoDetails
    },
    hasRemittanceData() {
      return this.appointment.Remittances.length > 0
    },
    getRemittanceData() {
      const remittanceDetails = [];

      for (let i = 0; i < this.appointment.Remittances.length; i++) {
        const remittance = this.appointment.Remittances[i];
        remittanceDetails.push({column: "Remittance ID:", value: remittance.MessageId})
        remittanceDetails.push({column: "Date Received:", value: helpers.getDisplayDateOnly(remittance.ProcessedOn)})
        remittanceDetails.push({column: "Value:", value: "£" + remittance.AmountPaid})
        remittanceDetails.push({column: "Status:", value: lookups.getStatusDisplayText(remittance.InvoiceStatus)})
        remittanceDetails.push({column: "Received From:", value: remittance.Client})
        
        if (i + 1 < this.appointment.Remittances.length) {
          remittanceDetails.push({column: "-"})
        }
      }

      return remittanceDetails
    },
    hasAuditData() {
      return this.appointment.AuditHistory.length > 0
    },
    getAuditData() {
      const auditDetails = [];

      for (let i = 0; i < this.appointment.AuditHistory.length; i++) {
        const audit = this.appointment.AuditHistory[i];
        
        if (audit.Property !== null) {
          auditDetails.push({column: "Property Changed:", value: audit.Property})
          if(audit.OldValue) {
            auditDetails.push({column: "Old Value:", value: audit.OldValue})
          }
          if(audit.NewValue) {
            auditDetails.push({column: "New Value:", value: audit.NewValue})
          }
          if(audit.Note) {
            auditDetails.push({column: "Note:", value: audit.Note})
          }
        }
        else if (audit.Action !== null) {
          auditDetails.push({column: "Action:", value: audit.Action})
        }
        else {
          auditDetails.push({column: "Error:", value: audit.Error})
        }
        auditDetails.push({column: "Date of Action:", value: helpers.getDisplayDateTime(audit.ChangedOn)})
        auditDetails.push({column: "Changed By:", value: audit.ChangedBy})
        auditDetails.push({column: "Last Modified:", value: this.appointment.LastModified})

        if (i + 1 < this.appointment.AuditHistory.length) {
          auditDetails.push({column: "-"})
        }
      }

      return auditDetails
    },
    remittanceHeader() {
      let header = "Remittances"

      if (this.hasRemittanceData) {
        header += " (" + this.appointment.Remittances.length + ")"
      }
      return header
    },
    auditHeader() {
      let header = "Audit Log"
      
      if (this.hasAuditData) {
        header += " (" + this.appointment.AuditHistory.length + ")"
      }
      return header
    }
  },
  methods: {
    closeClick(event) {
      this.toggleInvoiceInfo = true
      this.toggleRemittances = false 
      this.toggleAuditHistory = false
      this.$emit("appointment-details-closed")
      event.stopPropagation()
    },
    closePopupClick(event) {
      // Stops the cancelling of the popup within the popup area
      event.stopPropagation()
    },
    onToggleInvoiceInfoClick(event) {
      this.toggleInvoiceInfo = !this.toggleInvoiceInfo
      event.stopPropagation()
    },
    onToggleRemittancesClick(event) {
      this.toggleRemittances = !this.toggleRemittances
      event.stopPropagation()
    },
    onToggleAuditHistoryClick(event) {
      this.toggleAuditHistory = !this.toggleAuditHistory
      event.stopPropagation()
    }
  }
}
</script>

<style scoped>

div.appointment-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 1000;
}

div.appointment-popup {
  width: 645px;
  height: 100vh;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  right: -17px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: -2px 0 rgba(0, 0, 0, 0.1);
  box-sizing: content-box;
  overflow-y: scroll;
  transform: translateX(calc(100% + 628px));
  animation: slide-in 0.5s forwards;
}

@keyframes slide-in {
  100% { transform: translateX(0%); }
}

div.close > button {
  padding: 5px 15px 5px 15px;
  border: 1px solid rgba(22, 129, 145, 1);
  background-color: #F5F5F5;
  color: rgba(22, 129, 145, 1);
  line-height: 14px;
  font-size: 14px;
}

hr {
  margin: 10px;
}

div.float-container {
  margin: 10px;
}

span.case-ref {
  font-weight: normal;
  font-size: 15px;
  line-height: 40px;
  margin: 10px;
}

.panel-heading a {
  vertical-align: sub;
}

.panel-heading  a:before {
  content: "^";
  font-size: 22px;
  float: right;
  transition: all 0.2s;
  padding-top: 6px;
  transform: rotate(180deg);
}

.panel-heading.active a:before {
  transform: rotate(0deg);
}

table {
  width: 100%;
  border-radius: 5px;
}

table.borderless-table {
  border: none;
}

table.borderless-table tr > td {
  border: none;
}

table tr:first-child th:first-child {
  border-top-left-radius: 5px;
}

table tr:first-child th:last-child {
  border-top-right-radius: 5px;
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}

thead th {
  color: #ffffff;
  line-height: 15px;
  padding: 2px 5px;
  width: 50%;
  background-color: rgba(22, 129, 145, 1);
  cursor: pointer;
}

table td:first-child {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

table td:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

table tfoot tr:last-child td:first-child {
  border-left: none;
}

table tfoot tr:last-child td:last-child {
  border-right: none;
}

tfoot td {
  color: #ffffff;
  line-height: 10px;
  padding: 5px;
  width: 50%;
  background-color: rgba(22, 129, 145, 1);
}

tbody td {
  line-height: 15px;
  padding: 5px;
  width: 50%;
}

td hr {
  margin: 2px;
}

</style>
