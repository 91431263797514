<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <!-- Intentional - for future use -->
      </div>
      <div class="col">
        <!-- Intentional - for future use -->
      </div>
      <div class="col">
        <router-link to="/fee-lines" custom v-slot="{ navigate }">
          <button @click="navigate" role="link">View Fee Lines</button>
        </router-link>
      </div>
      <div class="col">
        <router-link to="/sage-files" custom v-slot="{ navigate }">
          <button @click="navigate" role="link">Sage Files</button>
        </router-link>
      </div>
      <div class="col">
        <!-- Intentional - for future use -->
      </div>
      <div class="col">
        <!-- Intentional - for future use -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePageButtons"
}
</script>

<style scoped>

button {
  color: white;
  background-color: #168191;
  width: 200px;
  height: 200px;
  padding: 10px;
  font-weight: bold;
  text-align: center;
  font-size: 23px;
  line-height: 32px;
  margin: 100px 20px 20px;
  border-style: none;
  border-radius: 10px;
  display: inline;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.12);
  text-transform: none;
}
</style>
