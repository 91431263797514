<template>
  <div>
    <span v-if="smallTitle" class="small-title">{{ title }}</span>
    <span v-if="!smallTitle" class="large-title">{{ title }}</span>
    <select v-model="selected" @change="onPeriodChanged" :disabled="entered">
      <option v-for="option in options" :key="option.id" :value="option.id">
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "SummaryPeriodFilter",
  props: {
    title: { Type: String },
    smallTitle: { type: Boolean },
    entered: { type: Boolean },
  },
  emits: ["selected-period-changed"],
  beforeMount() {
    this.options.push({ name: "Last 24 hours", id: "last 24 hrs" });
    this.options.push({
      name: "This week (starting Monday to now)",
      id: "this week",
    });
    this.options.push({
      name: "This Month (from month start to now)",
      id: "this month",
    });

    const currentDate = new Date();
    let month = currentDate.getMonth();
    let year = currentDate.getFullYear();

    // Add the last 18 months starting from the previous month (so we have 15 entries)
    while (this.options.length < 21) {
      // Role year over
      if (month === 0) {
        year--;
        month = 12;
      }

      const monthFormatted = String(month).padStart(2, "0");
      const name = monthFormatted + "/" + year;
      const value = year + "-" + monthFormatted;
      this.options.push({ name: name, id: value });

      month--;
    }

    this.selected = this.options[0].id;
  },
  methods: {
    onPeriodChanged() {
      this.raiseSelectedPeriodChanged();
    },
    raiseSelectedPeriodChanged() {
      this.$emit("selected-period-changed", { period: this.selected });
    },
  },
  watch: {
    entered(value) {
      if (value) {
        this.selected = "";
      }
    },
  },
  data() {
    return {
      selected: null,
      options: [],
    };
  },
};
</script>

<style scoped>
select {
  width: 270px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 20px;
  gap: 10px;
}

span.large-title {
  font-size: 16px;
  line-height: 24px;
}

span.small-title {
  /* inherit */
}
</style>
