import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUser, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Popper from "vue3-popper"

// Add any specific fonts into the library
library.add(faUser, faArrowUp, faArrowDown)

const app = createApp(App)

app
    .component('font-awesome-icon', FontAwesomeIcon)
    .component("Popper", Popper)
    .use(router)
    .use(VueAxios, axios)
    .mount('#app')
