<template>
  <div class="alert-top-right" v-if="toShowAlert" id="toastContainer">
    <div class="alert fade show" role="alert">
      <p v-if="message">{{ message }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToastMessage",
  mounted() {
    document.addEventListener("scroll", () => {
      this.adjustToastTop();
    });
  },
  methods: {
    showErrorAlert(message) {
      this.showAlert(message, this.toast.failure);
    },
    showSuccessAlert(message) {
      this.showAlert(message, this.toast.success);
    },
    showProcessingAlert(message) {
      this.showAlert(message, this.toast.processing);
    },
    showWarningAlert(message) {
      this.showAlert(message, this.toast.warning);
    },
    showAlert(message, alertType) {
      if (!this.currentTimeout) {
        clearTimeout(this.currentTimeout);
        this.currentTimeout = null;
      }
      this.message = message;
      this.toShowAlert = true;
      this.alertType = alertType;
      this.toast.background = alertType;
      this.currentTimeout = setTimeout(this.closeAlert, 8000);
      this.adjustToastTop();
    },
    closeAlert() {
      this.toShowAlert = false;
    },
    adjustToastTop() {
      if (window.pageYOffset <= 60) {
        this.toast.top = 60 - window.pageYOffset + "px";
      } else {
        this.toast.top = "0px";
      }
    },
  },
  data() {
    return {
      toShowAlert: false,
      message: "",
      alertType: "",
      currentTimeout: null,
      toast: {
        top: "60px",
        right: "0px",
        background: "#4caf50",
        success: "#4caf50",
        failure: "#ff5252",
        processing: "#d07f4f",
        warning: "#ffc107"
      },
    };
  },
};
</script>

<style scoped>
.alert-top-right {
  position: fixed;
  z-index: 1050;
  top: v-bind("toast.top");
  right: v-bind("toast.right");
  width: 354px;
  min-height: 104px;
  border-radius: 4px;
  color: #ffffff;
  text-align: left;
  background-color: v-bind("toast.background");
  transform: translateX(calc(100% + 354px));
  animation: slide-in 0.5s forwards;
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

.alert.fade.show {
  --bs-alert-margin-bottom: 0;
}
.alert-top-right p {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 24px;
}
</style>
