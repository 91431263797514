export const helpers = {
    sleep: sleep,
    getAppointmentDate: getAppointmentDate,
    getDisplayDateOnly: getDisplayDateOnly,
    getDisplayDateTime: getDisplayDateTime,
    totalPages: totalPages
}

async function sleep(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    })
}

function getAppointmentDate(value) {
    const date = new Date(value)
    return ("0" + date.getDate()).slice(-2) + "/" +
        ("0"+(date.getMonth()+1)).slice(-2) + "/" +
        date.getFullYear() + " @ " +
        ("0" + date.getHours()).slice(-2) + ":" +
        ("0" + date.getMinutes()).slice(-2)
}

function getDisplayDateOnly(value) {
    const date = new Date(value)
    return ("0" + date.getDate()).slice(-2) + "/" +
        ("0" + (date.getMonth()+1)).slice(-2) + "/" + date.getFullYear();
}

function getDisplayDateTime(value) {
    const date = new Date(value)
    return ("0" + date.getUTCDate()).slice(-2) + "/" +
        ("0" + (date.getUTCMonth()+1)).slice(-2) + "/" +
        date.getUTCFullYear() + " @ " +
        ("0" + date.getUTCHours()).slice(-2) + ":" +
        ("0" + date.getUTCMinutes()).slice(-2) + ":" +
        ("0" + date.getUTCSeconds()).slice(-2);
}

function totalPages(totalResults, pageSize) {
    let pageCount = Math.trunc(totalResults / pageSize)

    if (totalResults % pageSize !== 0) {
        pageCount++
    }

    return pageCount === 0 ? 1 : pageCount
}

export default helpers
