<template>
  <div class="container-fluid" v-if="summary">
    <div class="row">
      <div class="col">
        <Popper placement="bottom" hover content="The total number of fees received and updated for the date range.">
          <div>
            <div class="summary-title">
              Total Received & Updated
            </div>
            <div class="summary-value" :class="loading ? 'summary-value-loading' : ''">
              {{ summary.TotalProcessed }}
            </div>
          </div>
        </Popper>
      </div>
      <div class="col">
        <Popper placement="bottom" hover content="The total number of fees successfully invoiced to the client for the date range.">
          <div>
            <div class="summary-title">
              Invoiced
            </div>
            <div class="summary-value" :class="loading ? 'summary-value-loading' : ''">
              {{ summary.Invoiced }}
            </div>
          </div>
        </Popper>
      </div>
      <div class="col">
        <Popper placement="bottom" hover content="The total number of fees that require action for the date range.">
          <div>
            <div class="summary-title">
              Action Required
            </div>
            <div class="summary-value" :class="loading ? 'summary-value-loading' : ''">
              {{ summary.RequiresAction }}
            </div>
          </div>
        </Popper>
      </div>
      <div class="col">
        <Popper placement="bottom" hover content="The total unpaid value of invoices for the date range, including invoices requiring action.">
          <div>
            <div class="summary-title">
              Outstanding
            </div>
            <div class="summary-value" :class="loading ? 'summary-value-loading' : ''">
              £{{ summary.Outstanding.toLocaleString() }}
            </div>
          </div>
        </Popper>
      </div>
      <div class="col">
        <Popper placement="bottom" hover content="The total value paid for invoices for the date range.">
          <div>
            <div class="summary-title">
              Received
            </div>
            <div class="summary-value" :class="loading ? 'summary-value-loading' : ''">
              £{{ summary.Received.toLocaleString() }}
            </div>
          </div>
        </Popper>
      </div>
      <div class="col col-last">
        <Popper placement="bottom" hover content="The total of all invoices for the date range.">
          <div>
            <div class="summary-title">
              Total
            </div>
            <div class="summary-value" :class="loading ? 'summary-value-loading' : ''">
              £{{ summary.Total.toLocaleString() }}
            </div>
          </div>
        </Popper>
      </div>
    </div>
  </div>
  
</template>

<script>

export default {
  name: "SummaryTotals",
  props: {
    summary: { type: Object },
    loading: { type: Boolean }
  }
}
</script>

<style scoped>

div.summary-title {
  color: #168191;
  font-weight: bold;
}

div.summary-value {
  font-weight: bold;
}

div.col {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

div.col-last {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

:deep(.popper) {
  background-color: rgba(97, 97, 97, 0.9) !important;
  border-radius: 4px !important;
  color: #ffffff !important;
  padding: 10px !important;
  margin: 10px !important;
  font-size: 14px !important;
}

:deep(.popper #arrow::before) {
  background: #616161;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #616161;
}

div.summary-value-loading::after {
  content: counter(count);
  animation: counter 1s linear infinite alternate;
  counter-reset: count 0;
}

@keyframes counter {
  0% {
    counter-increment: count 0;
  }
  10% {
    counter-increment: count 1;
  }
  20% {
    counter-increment: count 2;
  }
  30% {
    counter-increment: count 3;
  }
  40% {
    counter-increment: count 4;
  }
  50% {
    counter-increment: count 5;
  }
  60% {
    counter-increment: count 6;
  }
  70% {
    counter-increment: count 7;
  }
  80% {
    counter-increment: count 8;
  }
  90% {
    counter-increment: count 9;
  }
  100% {
    counter-increment: count 10;
  }
}

</style>
