<template>
  <div>
    <div class="dropdown" @click="showDropdown($event)">
      <div class="over-select">
        <!-- By Design -->
      </div>
      <select @change="onStatusChanged" :disabled="entered">
        <option value="">{{ getSelectedText }}</option>
      </select>
    </div>
    <div class="multiselect" v-if="show">
      <ul>
        <li
          v-for="(option, index) in options"
          :key="index"
          @click="captureClick"
          class="multiselect-item"
        >
          <input
            type="checkbox"
            :id="index"
            :value="option.id"
            v-model="selectedOptions"
            @change="onStatusChanged"
            class="multiselect-input"
            :disabled="entered"
          />
          <label :for="index">{{ option.name }}</label>
        </li>
        <li>
          <hr />
          <button @click="showDropdown($event)">DONE</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { lookups } from "@/lookups";

export default {
  name: "StatusMultiSelect",
  props: {
    canSelect: { type: Boolean },
    entered: {
      type: Boolean,
    },
  },
  emits: ["status-selected"],
  data() {
    return {
      selectedOptions: [],
      show: false,
      options: lookups.statusList,
    };
  },
  watch: {
    entered(value) {
      if (value) {
        this.resetFilterActionable();
      }
    },
  },
  mounted() {
    this.selectedOptions.push(lookups.statusList[0].id);
  },
  computed: {
    getSelectedText() {
      if (this.selectedOptions.length > 1) {
        return "Multiple status selected";
      }

      const selectedId = this.selectedOptions[0];

      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i].id === selectedId) return this.options[i].name;
      }

      return this.options[0].name;
    },
  },
  methods: {
    showDropdown(args) {
      if (!this.canSelect) return;
      this.show = !this.show;

      if (this.show) {
        args.stopPropagation();
      }
    },
    filterActionable() {
      this.options = [];

      this.selectedOptions = [];
      lookups.statusList.forEach((s) => {
        if (s.actionable) {
          this.options.push(s);
          this.selectedOptions.push(s.id);
        }
      });

      this.raiseStatusSelected();
    },
    resetFilterActionable() {
      this.options = lookups.statusList;
      this.selectedOptions = [];
      this.selectedOptions.push(this.options[0].id);
      this.raiseStatusSelected();
    },
    onStatusChanged(event) {
      event.stopPropagation();

      if (this.selectedOptions.length === 0) {
        // The default is all if none are selected or unknown if filtered to actionable
        this.selectedOptions.push(this.options[0].id);
      } else if (this.selectedOptions.length > 1) {
        // More than one selected which might contain all
        if (this.selectedOptions[this.selectedOptions.length - 1] === null) {
          // If last is all then remove all but all
          this.selectedOptions = [];
          this.selectedOptions.push(this.options[0].id);
        } else {
          // All not last so make sure all is excluded from list
          const filteredStatusList = [];
          for (let i = 0; i < this.selectedOptions.length; i++) {
            if (this.selectedOptions[i] !== null) {
              filteredStatusList.push(this.selectedOptions[i]);
            }
          }

          this.selectedOptions = filteredStatusList;
        }
      }

      this.raiseStatusSelected();
    },
    captureClick(event) {
      event.stopPropagation();
    },
    raiseStatusSelected() {
      this.$emit("status-selected", { selectedOptions: this.selectedOptions });
    },
  },
};
</script>

<style scoped>
.dropdown {
  position: relative;
  cursor: pointer;
  width: 250px;
}

.multiselect {
  position: relative;
}

.multiselect-item {
  display: flex;
  align-items: center;
  line-height: 20px;
}
.multiselect-input {
  min-width: 24px;
}

select {
  width: 100%;
  margin: 10px 0 0 0;
}

ul {
  border: 1px solid #ddd;
  background-color: #ffffff;
  border-top: 0;
  border-radius: 0 0 3px 3px;
  left: 0;
  padding: 8px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  list-style: none;
  overflow: hidden;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14),
    0 1px 5px rgba(0, 0, 0, 0.12);
}

li {
  text-align: left;
  vertical-align: baseline;
}

button {
  float: right;
}

label {
  padding: 5px;
  vertical-align: bottom;
}

.over-select {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

input[type="checkbox"] {
  text-align: left;
  margin: 0;
}
</style>
