import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FeeLinesView from '../views/FeeLinesView.vue'
import SageFilesView from '../views/SageFilesView.vue'

const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: HomeView
    },
    {
        path: '/fee-lines',
        name: 'FeeLinesView',
        component: FeeLinesView
    },
    {
        path: '/sage-files',
        name: 'SageFilesView',
        component: SageFilesView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
