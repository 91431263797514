<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App'
}

</script>

<style>

body {
  background: linear-gradient(to bottom right, rgba(22, 129, 145, 0.2), rgba(209, 70, 10, 0.05), rgba(22, 129, 145, 0.2)) fixed;
  overflow-x: hidden;
}

#app {
  font-family: "Merriweather Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  width: 100vw;
}

div.view-container {
  padding: 25px;
}

div.view-rounded-container {
  border-radius: 10px;
  background: #ffffff;
  padding: 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 1px 5px rgba(0, 0, 0, 0.12);
}

div.clear {
  clear:both;
}

div.float-container {
}

div.float-child {
  float: left;
  margin-right: 20px;
}

input,
select {
  border: solid 1px lightgray;
  padding: 10px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  height: 40px;
}

input:focus,
select:focus {
  outline: none;
}

span {
  display: flex;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
}

input[type="checkbox"] {
  align-items: center;
  text-align: center;
  width: 24px;
  height: 24px;
  margin: 40px 0;
  box-shadow: none;
}

input[type="checkbox"]:checked {
  accent-color: rgba(22, 129, 145, 1);
}

input[type="text"] {
  margin: 10px 0 20px;
}

table {
  padding: 30px 12px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

th {
  color: #757575;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  text-align: left !important;
}

td {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  line-height: 14px;
  font-weight: normal;
  text-align: left !important;
}

button {
  background: rgba(22, 129, 145, 1);
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 36px;
  color: #FFFFFF;
  border: none;
  box-shadow: 0 3px 1px rgba(0, 0, 0, 0.2);
  border-radius: 20px !important;
  padding: 0 16px;
}

button:disabled {
  color: rgba(0, 0, 0, 0.26);
  background: rgba(0, 0, 0, 0.12);
  border: none;
}

button.actionable {
  float: left;
  margin-right: 10px;
  padding: 0 10px 0 10px;
  line-height: 20px;
  white-space: nowrap;
}

button.secondary {
  border: 1px solid rgba(22, 129, 145, 1);
  background-color: #F5F5F5;
  color: rgba(22, 129, 145, 1);
}

button.secondary:disabled {
  color: rgba(0, 0, 0, 0.26);
  background: rgba(0, 0, 0, 0.12);
  border: none;
  box-shadow: none;
}
</style>
