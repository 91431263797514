<template>
  <ToastMessage ref="toast" />
  <ToolBar
    :fee-lines-active="true"
    :loading="working"
    @login-success="onLoginSuccess"
  />
  <div class="view-container" v-if="isSignedIn" @click="captureClick">
    <AppointmentDetails
      :appointment="selectedAppointment"
      @appointment-details-closed="onAppointmentDetailsClosed"
    />
    <div class="view-rounded-container">
      <h2>Filter Fees</h2>
      <div class="float-container">
        <div class="float-child">
          <div>
            <span class="range">Date Range</span>
            <SummaryRangeFilter
              @selected-range-changed="onSelectedRangeChanged"
              @selected-range-changed2="onSelectedRangeChanged2"
              :entered="isActionRequiredDisabled || hasEnterInvoice"
            />
          </div>
        </div>
        <div class="float-child">
          <div>
            <span class="status">Status</span>
            <StatusMultiSelect
              @status-selected="onStatusSelected"
              ref="multiSelectStatus"
              :can-select="isAppointmentDetailsDisplayed"
              :entered="isActionRequiredDisabled || hasEnterInvoice"
            />
          </div>
        </div>
        <div class="float-child">
          <span>Case Reference</span>
          <input
            type="text"
            v-model="selectedCaseReference"
            :disabled="hasEnterInvoice"
          />
        </div>
        <div class="float-child">
          <span>Invoice Number</span>
          <input
            type="text"
            v-model="selectedInvoiceNumber"
            :disabled="isActionRequiredDisabled"
            @keypress="isNumberOnly($event)"
          />
        </div>
        <div class="float-child">
          <input
            id="actionRequired"
            type="checkbox"
            @change="onActionRequiredChange($event.target.checked)"
            :disabled="isActionRequiredDisabled || hasEnterInvoice"
          />
          <label for="actionRequired" class="action-required"
            >Show only action required</label
          >
        </div>
        <div class="float-child search">
          <button @click="searchClick" :disabled="working">SEARCH</button>
        </div>
        <div class="float-child export">
          <ExportButton
            :selected-range="selectedRange"
            :selected-range-end="selectedRange2"
            :selected-case-reference="selectedCaseReference"
            :selected-status-list="selectedStatusList"
            :total-results="totalResults"
            :can-export="hasFeeLineData && !working"
            @export-started="onExportStarted"
            @export-ended="onExportEnded"
            @toast-error="this.$refs.toast.showErrorAlert($event.message)"
          />
        </div>
      </div>

      <div class="clear" />

      <SummaryTotals :summary="summary" :loading="loading" />

      <div class="clear" />

      <div>
        <table class="table" v-if="hasFeeLineData">
          <thead>
            <tr>
              <th
                @click="sort(column.name, index)"
                v-for="(column, index) in columns"
                :key="index"
                :class="column.key && { sorted: column.sorted }"
              >
                {{ column.name }}
                <font-awesome-icon
                  :name="column.name"
                  v-if="activeIndex === index && column.arrow"
                  class="arrow"
                  :icon="
                    orderDir === 'asc'
                      ? ['fa', 'arrow-up']
                      : ['fa', 'arrow-down']
                  "
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in feeLineData.Results" :key="item">
              <td
                v-for="column in columns"
                v-bind:key="column"
                :class="column.key === 'Action' ? 'action-column' : ''"
              >
                <a
                  v-if="column.key === 'CaseReference'"
                  class="cell-value"
                  v-bind:href="getCaseReferenceLink(item['CaseGuid'])"
                  target="_blank"
                >
                  {{ getDisplayText(item, column.key) }}
                </a>
                <a
                  v-else-if="
                    column.key === 'ClientInvoiceNumber' &&
                    item['ClientInvoiceNumber'] > 0
                  "
                  class="cell-value"
                  @click="downloadInvoiceClick(item['ClientInvoiceNumber'])"
                  target="_blank"
                >
                  {{ item[column.key] }}
                </a>
                <span
                  v-else-if="column.key !== 'Action'"
                  class="cell-value"
                  @click="appointmentClick(item['AppointmentGuid'])"
                >
                  {{ getDisplayText(item, column.key) }}
                </span>
                <span
                  v-else-if="
                    column.key === 'Action' && !item.CanClose && !item.CanRetry
                  "
                  class="cell-value"
                  @click="appointmentClick(item['AppointmentGuid'])"
                >
                  &nbsp;
                </span>
                <RetryButton
                  v-if="column.key === 'Action' && item.CanRetry"
                  :case-reference="item['CaseReference']"
                  :appointment-guid="item['AppointmentGuid']"
                  :appointment-type="item['AppointmentType']"
                  :appointment-date="item['AppointmentDate']"
                  :results="feeLineData.Results"
                  :user="store.account.name"
                  :isAvailable="working"
                  @retry-started="onRetryStarted"
                  @retry-ended="onRetryEnded"
                  @toast-success="
                    this.$refs.toast.showSuccessAlert($event.message)
                  "
                  @toast-error="this.$refs.toast.showErrorAlert($event.message)"
                  @toast-processing="
                    this.$refs.toast.showProcessingAlert($event.message)
                  "
                  :title="
                    item['ClientInvoiceStatus'] ===
                      'unprocessed-fee-exceeded-acceptable-range' &&
                    item['AuditHistory']?.[0]?.OldValue !==
                      'unprocessed-fee-exceeded-acceptable-range'
                      ? 'PROCESS ANYWAY'
                      : 'RETRY'
                  "
                />
                <CloseButton
                  v-if="column.key === 'Action' && item.CanClose"
                  :appointment-guid="item['AppointmentGuid']"
                  :appointment-type="item['AppointmentType']"
                  :appointment-date="item['AppointmentDate']"
                  :case-reference="item['CaseReference']"
                  :results="feeLineData.Results"
                  :user="store.account.name"
                  :isAvailable="working"
                  @close-started="onCloseStarted"
                  @close-ended="onCloseEnded"
                  @toast-success="
                    this.$refs.toast.showSuccessAlert($event.message)
                  "
                  @toast-error="this.$refs.toast.showErrorAlert($event.message)"
                />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="11">
                <TablePaging
                  :default-page-size="20"
                  :page-size-options="[10, 20, 50, 100]"
                  :total-results="totalResults"
                  @page-number-changed="onPageNumberChanged"
                  @page-size-changed="onPageSizeChanged"
                />
              </td>
            </tr>
          </tfoot>
        </table>
        <h2 class="no-data" v-else>No data found for the selected filters</h2>
      </div>
    </div>
  </div>
  <div class="view-container" v-if="!isSignedIn">
    <div class="view-rounded-container signed-out-view-rounded-container">
      <h1 class="signed-out-header">Gateway Insurer Billing</h1>
    </div>
  </div>
</template>

<script>
import ToolBar from "@/components/ToolBar";
import SummaryTotals from "@/components/SummaryTotals";
import ToastMessage from "@/components/ToastMessage";
import { store } from "@/stores";
import api from "@/controllers";
import { lookups } from "@/lookups";
import helpers from "@/common";
import { config } from "@/config";
import AppointmentDetails from "@/components/AppointmentDetails";
import ExportButton from "@/components/ExportButton";
import CloseButton from "@/components/CloseButton";
import RetryButton from "@/components/RetryButton";
import TablePaging from "@/components/TablePaging";
import StatusMultiSelect from "@/components/StatusMultiSelect";
import SummaryRangeFilter from "@/components/SummaryRangeFilter";

export default {
  name: "FeeLinesView",
  components: {
    ToolBar,
    SummaryTotals,
    SummaryRangeFilter,
    ToastMessage,
    AppointmentDetails,
    ExportButton,
    CloseButton,
    RetryButton,
    TablePaging,
    StatusMultiSelect,
  },
  computed: {
    isSignedIn() {
      return store.account !== undefined;
    },
    totalResults() {
      return this.feeLineData.TotalResults;
    },
    isActionRequiredDisabled() {
      return this.hasSelectedCaseReference;
    },
    hasEnterInvoice() {
      return this.hasSelectedInvoiceNumber;
    },
    hasSelectedCaseReference() {
      return (
        this.selectedCaseReference != null &&
        this.selectedCaseReference.trim() !== ""
      );
    },
    hasSelectedInvoiceNumber() {
      return (
        this.selectedInvoiceNumber != null &&
        this.selectedInvoiceNumber.trim() !== ""
      );
    },
    hasFeeLineData() {
      return (
        this.feeLineData &&
        this.feeLineData.Results &&
        this.feeLineData.Results.length > 0
      );
    },
    working() {
      return this.loading || this.retrying || this.exporting || this.closing;
    },
    isAppointmentDetailsDisplayed() {
      return this.selectedAppointment === null;
    },
  },
  beforeMount() {
    this.rowsPerPageOptions.push({ name: "10", id: 10 });
    this.rowsPerPageOptions.push({ name: "20", id: 20 });
    this.rowsPerPageOptions.push({ name: "50", id: 50 });
    this.rowsPerPageOptions.push({ name: "100", id: 100 });
    this.selectedRowsPerPage = this.rowsPerPageOptions[1].id;
  },
  async mounted() {
    this.columns = [
      { name: "Case Reference", key: "CaseReference" },
      { name: "Last Modified", key: "LastModified", arrow: true, sorted: true },
      {
        name: "Appointment Date",
        key: "AppointmentDate",
        arrow: true,
        sorted: true,
      },
      { name: "Appointment Type", key: "AppointmentType" },
      { name: "Attendance", key: "Attendance" },
      { name: "£ Cost", key: "Cost", arrow: true, sorted: true },
      { name: "£ Balance", key: "Balance", arrow: true, sorted: true },
      { name: "Client Invoice ID", key: "ClientInvoiceNumber" },
      { name: "Status", key: "ClientInvoiceStatus", arrow: true, sorted: true },
      { name: "Invoice Date", key: "InvoiceDate", arrow: true, sorted: true },
      { name: "Action", key: "Action" },
    ];
  },
  watch: {
    isSignedIn(value) {
      if (value) {
        this.getFeeLineData();
      }
    },
  },
  data() {
    return {
      store,
      orderBy: "lastMod",
      orderDir: "asc",
      activeIndex: null,
      selectedRange: null,
      selectedRange2: null,
      selectedStatusList: [],
      selectedCaseReference: null,
      selectedInvoiceNumber: null,
      summary: {
        TotalProcessed: 0,
        Invoiced: 0,
        RequiresAction: 0,
        Outstanding: 0,
        Received: 0,
        Total: 0,
      },
      feeLineData: [],
      columns: [],
      selectedRowsPerPage: 10,
      rowsPerPageOptions: [],
      loading: false,
      selectedAppointment: null,
      retrying: false,
      exporting: false,
      closing: false,
    };
  },
  methods: {
    isNumberOnly(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) {
        return true;
      } else {
        e.preventDefault(),
          this.$refs.toast.showWarningAlert("Numerical values only");
      }
    },
    sort(val, index) {
      this.activeIndex = index;
      switch (val) {
        case "Appointment Date":
          this.orderBy = "appDate";
          this.orderDir = this.orderDir === "asc" ? "desc" : "asc";
          break;
        case "£ Cost":
          this.orderBy = "cost";
          this.orderDir = this.orderDir === "asc" ? "desc" : "asc";
          break;
        case "£ Balance":
          this.orderBy = "balance";
          this.orderDir = this.orderDir === "asc" ? "desc" : "asc";
          break;
        case "Status":
          this.orderBy = "status";
          this.orderDir = this.orderDir === "desc" ? "asc" : "desc";
          break;
        case "Invoice Date":
          this.orderBy = "invDate";
          this.orderDir = this.orderDir === "desc" ? "asc" : "desc";
          break;
        case "Last Modified":
          this.orderBy = "lastMod";
          this.orderDir = this.orderDir === "desc" ? "asc" : "desc";
          break;
        case "Invoice Number":
          this.orderBy = "invoiceId";
          this.orderDir = this.orderDir === "asc" ? "desc" : "asc";
          break;
      }
      this.store.count = 1;
      this.getFeeLineData();
    },
    captureClick() {
      // This is designed to handle global click on the page to close any required panels
      if (this.$refs.multiSelectStatus.show) {
        this.$refs.multiSelectStatus.show = false;
      }
    },
    async getFeeLineData() {
      try {
        this.loading = true;
        await helpers.sleep(250);
        const feeLineDataResponse = await api.getFeeLineData(
          this.selectedRange,
          this.selectedRange2,
          this.selectedStatusList,
          this.selectedCaseReference,
          this.store.count,
          this.selectedRowsPerPage,
          this.selectedInvoiceNumber,
          this.orderBy,
          this.orderDir
        );

        if (feeLineDataResponse) {
          this.loading = false;
          this.feeLineData = feeLineDataResponse;
          this.summary = this.feeLineData.SummaryTotals;
        } else {
          this.$refs.toast.showErrorAlert("Unable to get the fee line data.");
        }
      } finally {
        this.loading = false;
      }
      for (const item of this.feeLineData.Results) {
        if (item.LastModified === "0001-01-01T00:00:00") {
          item.LastModified = "N/A";
        } else {
          item.LastModified = helpers.getAppointmentDate(item.LastModified);
        }
        if (item.InvoiceDate === "0001-01-01T00:00:00") {
          item.InvoiceDate = "N/A";
        } else {
          item.InvoiceDate = helpers.getAppointmentDate(item.InvoiceDate);
        }
      }
    },
    async onActionRequiredChange(value) {
      this.store.count = 1;

      if (value) {
        this.$refs.multiSelectStatus.filterActionable();
      } else {
        this.$refs.multiSelectStatus.resetFilterActionable();
      }
    },
    async searchClick() {
      this.store.count = 1;
      if (this.selectedCaseReference) {
        this.$refs.multiSelectStatus.show = false;
      }

      await this.getFeeLineData();
    },
    appointmentClick(appointmentGuid) {
      this.selectedAppointment = this.feeLineData.Results.find(
        (r) => r.AppointmentGuid === appointmentGuid
      );
    },
    getDisplayText(item, columnKey) {
      if (columnKey === "ClientInvoiceStatus")
        return lookups.getStatusDisplayText(item[columnKey]);
      if (columnKey === "AppointmentDate")
        return helpers.getAppointmentDate(item[columnKey]);
      if (columnKey === "ClientInvoiceNumber") return "N/A";
      return item[columnKey];
    },
    getCaseReferenceLink(caseRefGuid) {
      return config.portalBaseUrl + "caseDetails/" + caseRefGuid;
    },
    async downloadInvoiceClick(invoiceNumber) {
      const fileDownloadResponse = await api.getInvoiceFileDownload(
        invoiceNumber,
        store.account.name
      );

      if (fileDownloadResponse) {
        const exportElement = document.createElement("a");
        exportElement.href = fileDownloadResponse.BlobUrl;
        exportElement.target = "_blank";
        exportElement.download = invoiceNumber + ".csv";
        exportElement.click();
      } else {
        this.$refs.toast.showErrorAlert(
          "Unable to download the invoice for " + invoiceNumber + "."
        );
      }
    },
    async onLoginSuccess() {
      await this.getFeeLineData();
    },
    onStatusSelected(args) {
      this.selectedStatusList = args.selectedOptions;
    },
    async onPageNumberChanged(args) {
      this.store.count = args.pageNumber;
      await this.getFeeLineData();
    },
    async onPageSizeChanged(args) {
      this.store.count = 1;
      this.selectedRowsPerPage = args.pageSize;
      await this.getFeeLineData();
    },
    onRetryStarted() {
      this.retrying = true;
    },
    onRetryEnded(args) {
      if (args.index > -1) {
        setTimeout(() => {
          this.getFeeLineData();
        }, 1000);
      }
      setTimeout(() => {
        this.retrying = false;
      }, 2000);
    },
    onCloseStarted() {
      this.closing = true;
    },
    onCloseEnded(args) {
      if (args.index > -1) {
        this.getFeeLineData();
      }
      this.closing = false;
    },
    onExportStarted() {
      this.exporting = true;
      this.$refs.multiSelectStatus.show = false;
    },
    onExportEnded() {
      this.exporting = false;
    },
    onAppointmentDetailsClosed() {
      this.selectedAppointment = null;
    },
    onSelectedRangeChanged(args) {
      this.selectedRange = args.start;
      this.$refs.multiSelectStatus.show = false;
    },
    onSelectedRangeChanged2(args) {
      this.selectedRange2 = args.end;
      this.$refs.multiSelectStatus.show = false;
    },
  },
};
</script>

<style scoped>
h2 {
  text-align: left;
  font-size: 20px;
  line-height: 32px;
}

h1.signed-out-header {
  text-align: center;
  padding-top: 10px;
}

h2.no-data {
  text-align: center;
}

label.action-required {
  float: right;
  padding-top: 40px;
  padding-left: 20px;
  font-weight: normal;
  cursor: pointer;
}

div.clear {
  margin-bottom: 20px;
}

div.search > button {
  margin-top: 38px;
  padding: 1px 12px 1px 12px;
}

div.export {
  float: right;
}

div.export > button {
  margin-top: 38px;
  padding: 0 15px 0 15px;
}

div.export > button:disabled {
  padding: 1px 15px 1px 15px;
}

span.cell-value {
  font-weight: normal;
}

td {
  cursor: pointer;
  width: 10px;
  word-wrap: break-word;
}

td:first-child {
  cursor: default;
}

th,
td > span {
  line-height: 14px;
}

th {
  white-space: nowrap;
}

select.status {
  width: 270px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 20px;
  gap: 10px;
}

span.status {
  display: flex;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
}
.action-column button.actionable {
  margin-bottom: 8px;
}
.action-column {
  padding-bottom: 0;
}
.arrow {
  cursor: pointer;
}
.range {
  margin-bottom: 10px;
}
.sorted {
  cursor: pointer;
  text-decoration: underline;
}
.sorted:hover {
  color: #168191;
}
</style>
