<template>
  <div>
    <span>{{ title }}</span>
    <select v-model="selected" @change="onStatusChanged">
      <option v-for="option in options" :key="option.id" :value="option.id">
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "SageFileStatus",
  props: {
    title: { Type: String }
  },
  emits: ["selected-sage-file-status-changed"],
  beforeMount() {
    this.options.push({ name: "All", id: "all"})
    this.options.push({ name: "Downloaded Before", id: "downloaded before"})
    this.options.push({ name: "Not Downloaded Before", id: "not downloaded before"})
    this.selected = this.options[0].id
  },
  methods: {
    onStatusChanged() {
      this.raiseSelectedStatusChanged()
    },
    raiseSelectedStatusChanged() {
      this.$emit("selected-sage-file-status-changed", { status: this.selected })
    }
  },
  data() {
    return {
      selected: null,
      options: []
    }
  }
}
</script>

<style scoped>

select {
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 20px;
  gap: 10px;
}

</style>
