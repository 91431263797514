<template>
  <button class="actionable" :disabled="isAvailable" data-bs-toggle="modal" :data-bs-target="'#closeModal' + appointmentGuid">
    CLOSE
  </button>

  <!-- Modal -->
  <div class="modal fade" :id="'closeModal' + appointmentGuid" tabindex="-1" role="dialog" aria-labelledby="closeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="closeModalLabel">Manually Close Fee</h5>
        </div>
        <div class="modal-body">
          <p>
            You have selected to manually close the {{ appointmentType }} fee on {{ appointmentDateDisplay }} for case {{ caseReference }}.  
          </p>
          <p>
            Manually closing this fee will set the remaining Balance to £0 and will prevent the fee from being processed any further. 
          </p>
          <p>
            If you would like to proceed, please press 'Confirm'.
          </p>  
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="secondary btn left" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-danger right" @click="closeClick()" data-bs-dismiss="modal">Confirm</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helpers from "@/common"
import api from "@/controllers"

export default {
  name: "CloseButton",
  props: {
    appointmentGuid: { type: String },
    appointmentDate: { type: String },
    appointmentType: { type: String },
    caseReference: { type: String },
    results: {type : Object },
    isAvailable: { type: Boolean },
    user: { type: String }
  },
  emits: ["close-started", "close-ended", "toast-success", "toast-error"],
  computed: {
    appointmentDateDisplay() {
      return helpers.getAppointmentDate(this.appointmentDate)
    },
    successMessage() {
      return "The " + this.appointmentType + " fee on " + this.appointmentDateDisplay + " has been successfully closed."
    },
    closeFailedMessage() {
      return "Failed to close " + this.appointmentType + " fee on " + this.appointmentDateDisplay + "."
    },
    unexpectedErrorMessage() {
      return "Unexpected error trying to close " + this.appointmentType + " fee on " + this.appointmentDateDisplay + "."
    }
  },
  methods: {
    async closeClick() {
      
      let closePaymentResponse = null
      let index = -1
      
      try {
        this.$emit("close-started")
        closePaymentResponse = await api.closePayment(this.caseReference, this.appointmentGuid,  this.user)
        index = this.results.findIndex(obj => obj.AppointmentGuid === closePaymentResponse.AppointmentGuid)
        
        if (closePaymentResponse && index > -1) {

          if (closePaymentResponse.IsClosed) {
            this.$emit("toast-success", { message: this.successMessage })
          }
          else {
            this.$emit("toast-error", { message: this.closeFailedMessage })
          }
        }
        else {
          this.$emit("toast-error", { message: this.unexpectedErrorMessage })
        }
      }
      catch {
        this.$emit("toast-error", { message: this.unexpectedErrorMessage })
      }
      finally {
        this.$emit("close-ended", {data: closePaymentResponse, index: index})
      }
    }
  }
}
</script>

<style scoped>

div.modal-footer > button.left {
  width: 120px;
  height: 44px;
  text-transform: uppercase;
  text-align: center;
  box-shadow: 0 3px 1px rgba(0, 0, 0, 0.2);
}

div.modal-footer > button.right {
  width: 120px;
  height: 44px;
  text-transform: uppercase;
  text-align: center;
  box-shadow: 0 3px 1px rgba(0, 0, 0, 0.2);
  background-color: #d1460a;
}

div.modal-header {
  border-bottom: none;
}

div.modal-content {
  width: 492px;
  height: 300px;
}

div.modal-body {
  padding-top: 0;
}

div.modal-body > p {
  font-size: 14px;
  line-height: 20px;
}

</style>
