<template>
  <button class="actionable" @click="retryClick()" :disabled="isAvailable">
    {{ title }}
  </button>
</template>

<script>
import api from "@/controllers"
import helpers from "@/common";

export default {
  name: "RetryButton",
  props: {
    caseReference: { type: String },
    title: { type: String },
    appointmentGuid: { type: String },
    appointmentDate: { type: String },
    appointmentType: { type: String },
    results: {type : Object },
    isAvailable: { type: Boolean },
    user: { type: String }
  },
  emits: ["retry-started", "retry-ended", "toast-success", "toast-error", "toast-processing"],
  computed: {
    reprocessingMessage() {
      return "The " + this.appointmentType + " fee on " +
        this.appointmentDateDisplay + " for case " +
        this.caseReference + " has been sent for reprocessing."
    },
    successReprocessingMessage() {
      return "The " + this.appointmentType + " fee on " +
          this.appointmentDateDisplay + " for case " +
          this.caseReference + " has been successfully sent for reprocessing."
    },
    failedReprocessingMessage() {
      return "The " + this.appointmentType + " fee on " +
          this.appointmentDateDisplay + " for case " +
          this.caseReference + " has failed reprocessing."
    },
    unexpectedErrorReprocessingMessage() {
      return "The " + this.appointmentType + " fee on " +
          this.appointmentDateDisplay + " for case " +
          this.caseReference + " has an encountered an unexpected error being sent for reprocessing."
    },
    appointmentDateDisplay() {
      return helpers.getAppointmentDate(this.appointmentDate)
    }
  },
  methods: {
    async retryClick() {

      let replayPaymentResponse = null
      let index = -1
      
      try {
        this.$emit("retry-started")
        this.$emit("toast-processing", { message: this.reprocessingMessage })
        
        replayPaymentResponse = await api.retryPayment(this.caseReference, this.appointmentGuid,  this.user)
        index = this.results.findIndex(obj => obj.AppointmentGuid === replayPaymentResponse.AppointmentGuid)
        
        if (replayPaymentResponse && index > -1) {

          if (replayPaymentResponse.CanRetry){
            this.$emit("toast-error", { message: this.failedReprocessingMessage })
          }
          else if (replayPaymentResponse.ClientInvoiceStatus === "pending") {
            this.$emit("toast-success", { message: this.successReprocessingMessage })
          }
          else {
            this.$emit("toast-error", { message: this.unexpectedErrorReprocessingMessage })
          }

        }
        else {
          this.$emit("toast-error", { message: this.unexpectedErrorReprocessingMessage })
        }
      }
      catch {
        this.$emit("toast-error", { message: this.unexpectedErrorReprocessingMessage })
      }
      finally {
        this.$emit("retry-ended", {data: replayPaymentResponse, index: index})
      }
    }
  }
}
</script>

<style scoped>

</style>
