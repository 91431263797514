<template>
  <div>
    <input
      type="date"
      class="first"
      v-model="startDate"
      @change="raiseSelectedRangeChanged"
      :disabled="entered"
    />
    <input
      type="date"
      v-model="endDate"
      @change="raiseSelectedRangeChanged2"
      :disabled="entered"
    />
  </div>
</template>

<script>
export default {
  name: "SummaryRangeFilter",
  props: {
    entered: {
      type: Boolean,
    },
  },

  watch: {
    entered(value) {
      if (value) {
        this.startDate = "";
        this.endDate = "";
      }
    },
  },

  methods: {
    raiseSelectedRangeChanged() {
      this.$emit("selected-range-changed", { start: this.startDate });
    },
    raiseSelectedRangeChanged2() {
      this.$emit("selected-range-changed2", { end: this.endDate });
    },
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      selectedRange: "",
      selectedRange2: "",
    };
  },
};
</script>

<style scoped>
.first {
  margin-right: 10px;
}
</style>