<template>
  <div>
    <span>{{ title }}</span>
    <select v-model="selected" @change="onPeriodChanged">
      <option v-for="option in options" :key="option.id" :value="option.id">
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "SageFilePeriodFilter",
  props: {
    title: { Type: String }
  },
  emits: ["selected-sage-file-period-changed"],
  beforeMount() {
    this.options.push({ name: "This week (starting Monday to now)", id: "this week"})
    this.options.push({ name: "Last week (starting Monday to Sunday)", id: "last week"})
    this.options.push({ name: "This Month (from month start to now)", id: "this month"})

    const currentDate = new Date();
    let month = currentDate.getMonth();
    let year = currentDate.getFullYear()
    
    // Add the last 12 months starting from the previous month (so we have 18 entries)
    while (this.options.length < 15) {
      
      // Role year over
      if (month === 0)
      {
        year--
        month = 12
      }
      
      const monthFormatted = String(month).padStart(2, '0')
      const name = monthFormatted + "/" + year
      const value = year + "-" + monthFormatted
      this.options.push({ name: name, id: value})
      
      month--
    }
    
    this.selected = this.options[0].id
  },
  methods: {
    onPeriodChanged() {
      this.raiseSelectedPeriodChanged()
    },
    raiseSelectedPeriodChanged() {
      this.$emit("selected-sage-file-period-changed", { period: this.selected })
    }
  },
  data() {
    return {
      selected: null,
      options: []
    }
  }
}
</script>

<style scoped>

select {
  width: 320px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 20px;
  gap: 10px;
}

</style>
