<template>
  <ToastMessage ref="toast" />
  <ToolBar :home-active="true" :loading="loading" @login-success="onLoginSuccess"/>
  <div class="view-container" v-if="isSignedIn">
    <h1>Gateway Insurer Billing</h1>
    <div class="view-rounded-container">
      <SummaryPeriodFilter title="Fees Raised In" :small-title="false" @selected-period-changed="onSelectedPeriodChanged" />
      <SummaryTotals :summary="summary" :loading="loading" />
      <HomePageButtons v-if="summary"/>
    </div>
  </div>
  <div class="view-container" v-if="!isSignedIn">
    <div class="view-rounded-container signed-out-view-rounded-container">
      <h1 class="signed-out-header">Gateway Insurer Billing</h1>  
    </div>
  </div>
</template>

<script>
import ToolBar from "@/components/ToolBar"
import SummaryTotals from "@/components/SummaryTotals"
import ToastMessage from "@/components/ToastMessage"
import SummaryPeriodFilter from "@/components/SummaryPeriodFilter"
import HomePageButtons from "@/components/HomePageButtons"
import { store } from '@/stores'
import api from '@/controllers'
import helpers from "@/common"

export default {
  name: "HomeView",
  components: {
    ToolBar,
    SummaryTotals,
    ToastMessage,
    SummaryPeriodFilter,
    HomePageButtons
  },
  computed: {
    isSignedIn() {
      return store.account !== undefined
    }
  },
  async mounted() {
    if (this.isSignedIn) {
      await this.getSummary()
    }
  },
  data() {
    return {
      store,
      summary: {
        TotalProcessed: 0,
        Invoiced: 0,
        RequiresAction: 0,
        Outstanding: 0,
        Received: 0,
        Total: 0
      },
      selectedPeriod: "last 24 hrs",
      loading: false
    }
  },
  methods: {
    async getSummary() {
      try {
        this.loading = true
        await helpers.sleep(250)
        
        const summaryResponse = await api.getPaymentSummary(this.selectedPeriod)

        if (summaryResponse) {
          this.loading = false
          this.summary = summaryResponse
        }
        else {
          this.$refs.toast.showErrorAlert("Unable to get the summary totals.")
        } 
      }
      finally {
        this.loading = false
      }
    },
    async onLoginSuccess() {
      await this.getSummary()
    },
    async onSelectedPeriodChanged(args) {
      this.selectedPeriod = args.period
      await this.getSummary()
    }
  }
}
</script>

<style scoped>

h1 {
  text-align: left;
  font-size: 34px;
}

h1.signed-out-header {
  text-align: center;
  padding-top: 10px;
}

div.signed-out-view-rounded-container {
  height: fit-content;
}

</style>
