<template>
  <div class="pagination">
    <span class="rows-per-page">Rows per page:</span>
    <select v-model="selectedRowsPerPage" @change="onRowsPerPageChanged">
      <option v-for="option in rowsPerPageOptions" :key="option.id" :value="option.id">
        {{ option.name }}
      </option>
    </select>
    <span class="page-of-pages">{{ count }} of {{ totalPages }}</span>
    <button @click="previousPageClick" class="pagination" :disabled="isPreviousPageDisabled">&lt;</button>
    <button @click="nextPageClick" class="pagination" :disabled="isNextPageDisabled">&gt;</button>
  </div>
</template>

<script>
import helpers from "@/common"
import { store } from '@/stores'

export default {
  name: "TablePaging",
  props: {
    totalResults: { type: Number },
    pageSizeOptions: { type: Array },
    defaultPageSize: { type: Number },
    pagingContext: { type: String },
    updating: { type : Boolean },
  },
  emits: ["page-number-changed", "page-size-changed"],
  data() {
    return {
      store,
      selectedRowsPerPage: 10,
      rowsPerPageOptions: [],
    }
  },
  beforeMount() {
    this.pageSizeOptions.forEach(o => {
      this.rowsPerPageOptions.push({name: o.toString(), id: o})  
    })
    this.selectedRowsPerPage = this.rowsPerPageOptions.find(p => p.id === this.defaultPageSize).id
  },
  computed: {
    count(){
      return this.store.count
    },
    totalPages() {
      return helpers.totalPages(this.totalResults ?? 1, this.selectedRowsPerPage)
    },
    isPreviousPageDisabled() {
      if (this.updating) return true
      return this.store.count === 1
    },
    isNextPageDisabled() {
      if (this.updating) return true
      return this.store.count === this.totalPages
    }
  },
  methods: {
    onRowsPerPageChanged() {
      this.store.count = 1
      this.raisePageSizeChanged()
    },
    nextPageClick() {
      if (this.store.count === this.totalPages) return

      this.store.count++
      this.raisePageNumberChanged()
    },
    previousPageClick() {
      if (this.store.count === 1) return

      this.store.count--
      this.raisePageNumberChanged()
    },
    raisePageNumberChanged() {
      const args = { 
        pageNumber: this.store.count, 
        pagingContext: this.pagingContext 
      }
      this.$emit("page-number-changed", args)
    },
    raisePageSizeChanged() {
      const args = {
        pageSize: this.selectedRowsPerPage,
        pagingContext: 1
      }
      this.$emit("page-size-changed", args)
    },
    updatePageSize(pageSize) {
      this.store.count = 1
      this.selectedRowsPerPage = pageSize
    }
  }
}
</script>

<style scoped>

div.pagination {
  float: right;
  align-items: center;
}

div.pagination > button {
  border: none;
  background: transparent;
  font-size: 24px;
  line-height: 100%;
  width: 24px;
  height: 24px;
  margin: 10px;
}

div.pagination > select {
  padding: 0;
  height: 25px;
  font-size: 12px;
  line-height: 20px;
}

span.rows-per-page {
  font-size: 12px;
  line-height: 20px;
  width: 90px;
  height: 20px;
  margin: 10px;
}

span.page-of-pages {
  font-size: 12px;
  line-height: 20px;
  width: 53px;
  height: 20px;
  margin: 20px;
}

button.pagination {
  color: rgba(0, 0, 0, 0.54);
  border-radius: 0 !important;
  box-shadow: none;
}

button.pagination:disabled {
  color: rgba(0, 0, 0, 0.2);
}
</style>
