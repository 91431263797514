<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white navbar-custom">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarOptions" 
              aria-controls="navbarOptions" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <a class="navbar-brand" href="/"><img src="../assets/logo.svg" alt="Logo"/></a>
      <div v-if="isSignedIn" class="collapse navbar-collapse" id="navbarOptions">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link to="/" class="nav-link" :class="{ active: homeActive }">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/fee-lines" class="nav-link" :class="{ active: feeLinesActive }">Fee Lines</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/sage-files" class="nav-link" :class="{ active: sageFilesActive }">Sage Files</router-link>
          </li>
        </ul>
      </div>
      <div v-if="isSignedIn" class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav mb-2 mb-lg-0 justify-content-end">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" 
               aria-haspopup="true" aria-expanded="false"><font-awesome-icon icon="fa-solid fa-user"/> {{ store.account.name }}</a>
            <div class="dropdown-menu dropdown-menu-end">
              <a class="dropdown-item" href="#" @click="signOut">Log out</a>
            </div>
          </li>
        </ul>
        <img src="../assets/loading.gif" alt="Loading GIF" class="loading" v-if="loading"/>
      </div>
      <div v-if="!isSignedIn" class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav mb-2 mb-lg-0 justify-content-end">
          <li>
            <a class="nav-item sign-in" href="#" @click="signIn">Sign In</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { PublicClientApplication, EventType } from "@azure/msal-browser"
import {loginRequest, logoutRequest, msalConfig} from "@/auth"
import { store } from '@/stores'

export default {
  name: "ToolBar",
  props: {
    homeActive: { type: Boolean },
    feeLinesActive: { type: Boolean },
    sageFilesActive: { type: Boolean },
    loading: { type: Boolean }
  },
  emits: ["login-success"],
  computed: {
    isSignedIn() {
      return store.account !== undefined
    }
  },
  data() {
    return {
      store
    }
  },
  async mounted() {
    this.clientApp = new PublicClientApplication(msalConfig)
    this.clientApp.addEventCallback(message => {
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        this.$emit("login-success")
      }
    });
    await this.clientApp.handleRedirectPromise()
    
    const accounts = this.clientApp.getAllAccounts()
    if (accounts.length === 0) {
      return;
    }
    this.store.account = accounts[0]
     
  },
  methods: {
    async signIn() {
      const accounts = this.clientApp.getAllAccounts()
      if (accounts.length === 0) {
        await this.clientApp.loginRedirect(loginRequest)
      }
    },
    async signOut() {
      await this.clientApp.logoutRedirect(logoutRequest)
      this.store.account = undefined
    }
  }
};
</script>

<style scoped>

li:not(.dropdown) {
  padding-right: 30px;
}

li > a {
  color: #168191 !important;
  text-decoration: none;
}

li > a.active {
  color: #d1460a !important;
}

li > a:hover:is(a.active) {
  background-color: rgba(209, 70, 10, 0.25);
}

li > a:hover:not(a.active, a.dropdown-toggle) {
  background-color: rgba(22, 129, 145, 0.25);
}

li > a.active {
  border-bottom: 5px solid #d1460a;
}

li > a:hover:not(a.active, a.dropdown-toggle) {
  transition: none;
  border-bottom: 5px solid transparent;
}

li > a.sign-in {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

nav {
  padding: 0 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.navbar {
  min-height: 56px;
}

.navbar-brand {
  padding: 0;
  height: 56px;
  line-height: 56px;
}

.navbar-toggle {
  margin-top: 20px !important;
  padding: 9px 10px !important;
}

.navbar-nav > li > a {
  /* (56px - line-height of 20px) / 2 = 18px */
  padding-top: 18px;
  padding-bottom: 18px;
  line-height: 20px;
  font-size: 14px;
}

img.loading {
  width: 26px;
  height: 20px; 
  margin: 5px;
}
</style>
