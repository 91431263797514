<template>
  <ToastMessage ref="toast" />
  <ToolBar :sage-files-active="true" :loading="working" @login-success="onLoginSuccess"/>
  <div class="view-container" v-if="isSignedIn">
    <div class="view-rounded-container">
      <h2>Filter Fees</h2>
      <div class="float-container">
        <div class="float-child">
          <SageFilePeriodFilter title="Date Range" @selected-sage-file-period-changed="onSelectedSageFilePeriodChanged" />
        </div>
        <div class="float-child">
          <SageFileStatus title="Status" @selected-sage-file-status-changed="onSelectedSageFileStatusChanged" />
        </div>
      </div>
      <div class="clear"></div>
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <!-- By Design -->
          </div>
          <div class="col">
            <table class="table">
              <caption>Invoice Files</caption>
              <thead>
                <tr>
                  <th class="date">Date</th>
                  <th class="downloaded">Downloaded Before</th>
                  <th class="action">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in sageFileData.Results" :key='item'>
                  <td>{{ getDisplayText(item, "Date") }}</td>
                  <td>{{ getDisplayText(item, "DownloadedBefore") }}</td>
                  <td v-if="showFileDownloadButton(item)">
                    <DownloadButton :user="store.account.name" :date="item['Date']" :context="sageFilesFilter" @downloaded="onDownloaded"/>
                  </td>
                  <td v-else><span class="no-file">NO FILE</span></td>
                </tr>
                <tr v-if="!hasSageFileData">
                  <td colspan="3">
                    <h2 class="no-data">No data found for the selected filters</h2>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3">
                    <TablePaging 
                        :default-page-size="10" 
                        :page-size-options="[10, 15, 20]" 
                        :total-results="totalResults" 
                        :paging-context="sageFilesFilter"
                        :updating="working"
                        @page-number-changed="onPageNumberChanged"
                        @page-size-changed="onPageSizeChanged"
                        ref="sageFilePaging" />
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="col">
            <table class="table">
              <caption>Remittance Files</caption>
              <thead>
                <tr>
                  <th class="date">Date</th>
                  <th class="downloaded">Downloaded Before</th>
                  <th class="action">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in remittanceFileData.Results" :key='item'>
                  <td>{{ getDisplayText(item, "Date") }}</td>
                  <td>{{ getDisplayText(item, "DownloadedBefore") }}</td>
                  <td v-if="showFileDownloadButton(item)">
                    <DownloadButton :user="store.account.name" :date="item['Date']" :context="remittanceFileFilter" @downloaded="onDownloaded"/>
                  </td>
                  <td v-else><span class="no-file">NO FILE</span></td>
                </tr>
                <tr v-if="!hasRemittanceFileData">
                  <td colspan="3">
                    <h2 class="no-data">No data found for the selected filters</h2>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3">
                    <TablePaging 
                        :default-page-size="10" 
                        :page-size-options="[10, 15, 20]" 
                        :total-results="totalResults" 
                        :paging-context="remittanceFileFilter"
                        :updating="working"
                        @page-number-changed="onPageNumberChanged"
                        @page-size-changed="onPageSizeChanged"
                        ref="remittanceFilePaging" />
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
         <div class="col">
           <!-- By Design -->
         </div>
        </div>
      </div>
    </div>
  </div>
  <div class="view-container" v-if="!isSignedIn">
    <div class="view-rounded-container signed-out-view-rounded-container">
      <h1 class="signed-out-header">Gateway Insurer Billing</h1>
    </div>
  </div>
</template>

<script>
import ToolBar from "@/components/ToolBar"
import ToastMessage from "@/components/ToastMessage"
import SageFilePeriodFilter from "@/components/SageFilePeriodFilter"
import SageFileStatus from "@/components/SageFileStatus"
import { store } from '@/stores'
import helpers from "@/common"
import DownloadButton from "@/components/DownloadButton"
import TablePaging from "@/components/TablePaging"
import api from "@/controllers";

export default {
  name: "SageFilesView",
  components: {
    ToolBar,
    ToastMessage,
    SageFilePeriodFilter,
    SageFileStatus,
    DownloadButton,
    TablePaging
  },
  computed: {
    isSignedIn() {
      return store.account !== undefined
    },
    working() {
      return this.loading
    },
    totalResults() {
      // Either the sage files or remittances will contain the correct total - just use the first one that is valid
      return this.sageFileData ? this.sageFileData.TotalResults 
          : (this.remittanceFileData ? this.remittanceFileData.TotalResults : 0)
    },
    hasSageFileData() {
      return this.sageFileData && this.sageFileData.TotalResults > 0
    },
    hasRemittanceFileData() {
      return this.remittanceFileData && this.remittanceFileData.TotalResults > 0
    }
  },
  async mounted() {
    if (this.isSignedIn) {
      await this.getSagaFileData()
      await this.getRemittanceFileData()
    }
  },
  data() {
    return {
      store,
      selectedPeriod: "this week",
      selectedStatus: "all",
      loading: false,
      selectedSageFilePageNumber: 1,
      selectedRemittanceFilePageNumber: 1,
      selectedRowsPerPage: 10,
      sageFileData: [],
      remittanceFileData: [],
      sageFilesFilter: "sage",
      remittanceFileFilter: "remittance"
    }
  },
  methods: {
    async getSagaFileData() {
      try {
        this.loading = true
        const sageFileDataResponse = await api.getSagaFileData(
            this.selectedPeriod,
            this.selectedStatus,
            this.sageFilesFilter,
            this.selectedSageFilePageNumber,
            this.selectedRowsPerPage)

        if (sageFileDataResponse) {
          this.loading = false
          this.sageFileData = sageFileDataResponse
        }
        else {
          this.$refs.toast.showErrorAlert("Unable to get the sage file data.")
        }
      }
      finally {
        this.loading = false
      }
    },
    async getRemittanceFileData() {
      try {
        this.loading = true
        const remittanceFileDataResponse = await api.getSagaFileData(
            this.selectedPeriod,
            this.selectedStatus,
            this.remittanceFileFilter,
            this.selectedRemittanceFilePageNumber,
            this.selectedRowsPerPage)

        if (remittanceFileDataResponse) {
          this.loading = false
          this.remittanceFileData = remittanceFileDataResponse
        }
        else {
          this.$refs.toast.showErrorAlert("Unable to get the remittance file data.")
        }
      }
      finally {
        this.loading = false
      }
    },
    getDisplayText(item, columnKey) {
      if (columnKey === "Date") return helpers.getDisplayDateOnly(item[columnKey])
      if (columnKey === "DownloadedBefore") { 
        
        if (item["DownloadedBefore"] && item["FileExists"]) return "Yes"
        if (!item["DownloadedBefore"] && item["FileExists"]) return "No"
        return "N/A"
      }
      return item[columnKey]
    },
    showFileDownloadButton(item) {
      return item["FileExists"]
    },
    async onLoginSuccess() {
      await this.getSagaFileData()
      await this.getRemittanceFileData()
    },
    onDownloaded(args) {
      const results = args.context === this.sageFilesFilter ? this.sageFileData.Results : this.remittanceFileData.Results
      const result = results.find(d => d.Date === args.date)
      result.DownloadedBefore = true
    },
    async onPageNumberChanged(args) {
      if (args.pagingContext === this.sageFilesFilter) {
        this.selectedSageFilePageNumber = args.pageNumber
        await this.getSagaFileData()
      }
      if (args.pagingContext === this.remittanceFileFilter) {
        this.selectedRemittanceFilePageNumber = args.pageNumber
        await this.getRemittanceFileData()
      }
    },
    async onPageSizeChanged(args) {
      this.selectedRowsPerPage = args.pageSize
      this.selectedSageFilePageNumber = 1
      this.selectedRemittanceFilePageNumber = 1

      if (args.pagingContext === this.sageFilesFilter) {
        this.$refs.remittanceFilePaging.updatePageSize(args.pageSize)
      }

      if (args.pagingContext === this.remittanceFileFilter) {
        this.$refs.sageFilePaging.updatePageSize(args.pageSize)
      }

      await this.getSagaFileData()
      await this.getRemittanceFileData()
    },
    async onSelectedSageFileStatusChanged(args) {
      this.selectedStatus = args.status
      this.selectedSageFilePageNumber = 1
      this.selectedRemittanceFilePageNumber = 1
      this.$refs.sageFilePaging.updatePageSize(this.selectedRowsPerPage)
      this.$refs.remittanceFilePaging.updatePageSize(this.selectedRowsPerPage)
      await this.getSagaFileData()
      await this.getRemittanceFileData()  
    },
    async onSelectedSageFilePeriodChanged(args) {
      this.selectedPeriod = args.period
      this.selectedSageFilePageNumber = 1
      this.selectedRemittanceFilePageNumber = 1
      this.$refs.sageFilePaging.updatePageSize(this.selectedRowsPerPage)
      this.$refs.remittanceFilePaging.updatePageSize(this.selectedRowsPerPage)
      await this.getSagaFileData()
      await this.getRemittanceFileData()
    }
  }
}
</script>

<style scoped>

h2 {
  text-align: left;
  font-size: 20px;
  line-height: 32px;
}

h1 {
  text-align: left;
  font-size: 34px;
}

h1.signed-out-header {
  text-align: center;
  padding-top: 10px;
}

h2.no-data {
  text-align: center;
}

div.signed-out-view-rounded-container {
  height: fit-content;
}

caption { 
  caption-side: top;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

table {
  width: 450px !important;
  margin: auto;
}

th.date {
  width: 120px;
}

th.downloaded {
  width: 165px;
}

th.action {
  width: 165px;
}

td {
  height: 38px;
  vertical-align: middle;
}

span.no-file {
  border-radius: 20px;
  color: rgba(0, 0, 0, 0.26);
  background-color: rgba(0, 0, 0, 0.12);
  padding: 0 10px 0 10px;
  line-height: 20px;
  width: 76px;
}

</style>
