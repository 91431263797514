<template>
  <button class="actionable" @click="downloadClick">
    DOWNLOAD
  </button>
</template>

<script>
import api from "@/controllers"

export default {
  name: "DownloadButton",
  props: {
    user: { type: String },
    date: { type: String },
    context: { type: String }
  },
  emits: ["downloaded"],
  methods: {
    async downloadClick() {
      const fileDownloadResponse = await api.getFileDownload(this.date, this.context, this.user)
      const exportElement = document.createElement("a")
      exportElement.href = fileDownloadResponse.BlobUrl
      exportElement.target = "_blank"
      exportElement.download = this.context + ".zip"
      exportElement.click()
      this.$emit("downloaded", { date: this.date, context: this.context })
    }
  }
}
</script>

<style scoped>

button.actionable {
  padding: 0 8px 0 10px;
}

</style>
