export const lookups = {
    statusList: [
        { name: "All", id: null, actionable: false },
        { name: "Unknown", id: "unknown", actionable: true },
        { name: "Pending", id: "pending", actionable: false },
        { name: "Partially paid", id: "part-paid", actionable: true },
        { name: "Partially Paid - With Patientzone", id: "partially-paid-with-patient-zone", actionable: true },
        { name: "Unpaid", id: "unpaid", actionable: true },
        { name: "Unpaid - With Patientzone", id: "unpaid-with-patient-zone", actionable: true },
        { name: "Fully paid", id: "fully-paid", actionable: false },
        { name: "Manually closed down", id: "manually-closed-down", actionable: false },
        { name: "Complete - No fee due", id: "complete-no-fee-due", actionable: false },
        { name: "Failed - Missing DOB", id: "failed-missing-DOB", actionable: true },
        { name: "Failed - Invalid email format", id: "failed-invalid-email-format", actionable: true },
        { name: "Failed - No membership number", id: "failed-missing-core-membership-number", actionable: true },
        { name: "Failed - No patient details", id: "failed-no-patient-details", actionable: true },
        { name: "Rejected by Healthcode", id: "rejected-by-healthcode", actionable: true },
        { name: "Rejected - Membership lapsed", id: "rejected-by-healthcode-membership-lapsed", actionable: true },
        { name: "Rejected - Membership number mismatch", id: "rejected-membership-number-mismatch", actionable: true },
        { name: "Unprocessed - Fee exceeded acceptable range", id: "unprocessed-fee-exceeded-acceptable-range", actionable: true },
        { name: "Member enquiry failed", id: "member-enquiry-failed", actionable: true },
    ],
    getStatusDisplayText(statusId) {
        return this.statusList.find(s => s.id === statusId)?.name
    },
    isActionable(statusId) {
        return this.statusList.find(s => s.id === statusId && s.actionable) !== undefined
    }
};
